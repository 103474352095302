import { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ThemeProvider, createTheme, makeStyles, StylesProvider } from '@mui/material/styles'
import TagEasyDescription from "./components/TagEasyDescription";

const theme = createTheme();
function App() {


  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/description" element={<TagEasyDescription />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
