import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from "./index.module.scss";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
const drawerWidth = 240;

function TagEasyDescription(props) {
    const [target, setTarget] = useState("未複習之遺忘曲線 v.s AI提醒複習訓練之記憶曲線.html");
    const [htmlData, setHtmlData] = useState();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const targetDatas = [
        {
            name: "未複習之遺忘曲線 v.s AI提醒複習訓練之記憶曲線",
            target: "未複習之遺忘曲線 v.s AI提醒複習訓練之記憶曲線.html"
        },
        {
            name: "TAG EASY的學習機制",
            target: "TAG EASY的學習機制.html"
        },
        {
            name: "TAG EASY的學習口袋",
            target: "TAG EASY的學習口袋.html"
        },
        {
            name: "TAG EASY的知識庫",
            target: "TAG EASY的知識庫.html"
        }
        ,
        {
            name: "AI記憶演算法之專利",
            target: "AI記憶演算法之專利.html"
        }
    ];
    const handlerOnClick = (item) => {
        setTarget(item.target);
        setMobileOpen(false);
    }

    const drawer = (
        <div>
            <Toolbar />
            <List>
                {
                    targetDatas.map(item => {
                        return (
                            <ListItem key={item.name} disablePadding
                                onClick={() => handlerOnClick(item)
                                } >
                                <ListItemButton>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                }
            </List>
        </div >
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
        fetch(target, { method: 'GET' })
            .then(response => response.text())
            .then((res) => {
                setHtmlData(res)
            });

    }, [target])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon></MenuIcon>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        TAGEASY 說明資料
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />

                <div className={styles.wrapper}>
                    <div className={styles.main}
                        dangerouslySetInnerHTML={{ __html: htmlData }}
                    />
                </div>
            </Box >
        </Box >
    );
}

export default TagEasyDescription;